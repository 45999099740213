import { Injectable } from '@angular/core';

@Injectable()
export class ImageService {

  private static readonly URL_BASE = 'assets/images';

  getSmallAlbumCoverImg(artistId: string, albumId: string): string {
    if (!artistId || !albumId) {
      return 'https://via.placeholder.com/150x150&text=Loading';
    }
    return `${ImageService.URL_BASE}/albums/${artistId}/${albumId}_front_250.jpg`;
  }

  getLargeAlbumCoverImg(artistId: string, albumId: string): string {
    if (!artistId || !albumId) {
      return 'https://via.placeholder.com/300x300&text=Loading';
    }
    return `${ImageService.URL_BASE}/albums/${artistId}/${albumId}_front_500.jpg`;
  }

  getArtistImg(artistId: string): string {
    return `${ImageService.URL_BASE}/artists/${artistId}.jpg`;
  }
}
