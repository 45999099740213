import { Injectable } from '@angular/core';
import {LocalStorageService} from './LocalStorageService';

@Injectable()
export class MusicCollectionSettingsService {

  private static readonly ARTIST_SORT_VIEW_LOCAL_STORAGE_KEY = 'mikebgrove-musiccollection.artistSortView';

  /*
    the valid values for the ARTIST_SORT_VIEW_LOCAL_STORAGE_KEY setting above
   */
  public static readonly ARTIST_SORT_ALPHABETICAL = 'ALPHABETICAL';
  public static readonly ARTIST_SORT_ALBUM_COUNT = 'ALBUM_COUNT';

  private static readonly ALBUM_VIEW_LOCAL_STORAGE_KEY = 'mikebgrove-musiccollection.albumView';

  /*
    the valid values for ALBUM_VIEW_LOCAL_STORAGE_KEY
   */
  public static readonly TILES = 'TILES';
  public static readonly TABLE = 'TABLE';

  constructor(
    private localStorageService: LocalStorageService) {
  }

  getArtistSortViewFromLocalStorage(defaultIfUnsetOrInvalid: string): string {
    let artistSortView: string;
    const storedArtistSortView: string = this.localStorageService.getPropertyValue(
      MusicCollectionSettingsService.ARTIST_SORT_VIEW_LOCAL_STORAGE_KEY);
    switch (storedArtistSortView) {
      case MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL:
      case MusicCollectionSettingsService.ARTIST_SORT_ALBUM_COUNT:
        console.log('retrieved artist sort view of ' + storedArtistSortView + ' from local storage');
        artistSortView = storedArtistSortView;
        break;
      default:
        console.log('did not find artist sort view in local storage, using default of ' + defaultIfUnsetOrInvalid);
        artistSortView = defaultIfUnsetOrInvalid;
    }
    return artistSortView;
  }

  public storeArtistSortViewInLocalStorage(artistSortView): void {
    console.log('Storing artist sort view of ' + artistSortView + ' in local storage');
    this.localStorageService.savePropertyValue(MusicCollectionSettingsService.ARTIST_SORT_VIEW_LOCAL_STORAGE_KEY, artistSortView);
  }

  public getAlbumViewFromLocalStorage(defaultIfUnsetOrInvalid: string): string {
    let albumView: string;
    const storedAlbumView: string = this.localStorageService.getPropertyValue(MusicCollectionSettingsService.ALBUM_VIEW_LOCAL_STORAGE_KEY);
    switch (storedAlbumView) {
      case MusicCollectionSettingsService.TILES:
      case MusicCollectionSettingsService.TABLE:
        console.log('retrieved album view of ' + storedAlbumView + ' from local storage');
        albumView = storedAlbumView;
        break;
      default:
        console.log('did not find album view in local storage, using default of ' + defaultIfUnsetOrInvalid);
        albumView = defaultIfUnsetOrInvalid;
    }
    return albumView;
  }

  public storeAlbumViewInLocalStorage(albumView: string): void {
    console.log('Storing album view of ' + albumView + ' in local storage');
    this.localStorageService.savePropertyValue(MusicCollectionSettingsService.ALBUM_VIEW_LOCAL_STORAGE_KEY, albumView);
  }

}
