import { Component } from '@angular/core';

@Component({
  selector: 'app-music-collection-navbar',
  templateUrl: './music-collection-navbar.component.html',
  styleUrls: ['./music-collection-navbar.component.scss']
})
export class MusicCollectionNavbarComponent {

}
