import {AlbumSummaryWithPlaceToStart} from './AlbumSummaryWithPlaceToStart';

export class AlbumSummaryWithPlaceToStartCollection {
  albumSummaries: AlbumSummaryWithPlaceToStart[];

  constructor() {
    this.albumSummaries = new Array<AlbumSummaryWithPlaceToStart>();
  }

  deserializeAlbums(albumsJson: AlbumSummaryWithPlaceToStart[]): void {
    for (const albumJson of albumsJson) {
      const albumSummaryWithPlaceToStart = new AlbumSummaryWithPlaceToStart();
      albumSummaryWithPlaceToStart.deserialize(albumJson);
      this.albumSummaries.push(albumSummaryWithPlaceToStart);
    }
  }
}
