import { Injectable } from '@angular/core';
import {MusicCollectionService} from './MusicCollectionService';
import {Artist} from '../model/Artist';

@Injectable()
export class ArtistService {

  artist: Artist;

  constructor(
    private musicCollectionService: MusicCollectionService) { }

  loadArtist(artistId: string): void {
    this.musicCollectionService.getArtist(artistId)
      .subscribe(artist => this.artist = artist);
  }
}
