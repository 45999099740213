import {AlbumSummaryWithPlaceToStart} from './AlbumSummaryWithPlaceToStart';
import {AlbumNavigationMap} from './AlbumNavigationMap';

export class AlbumNavigation {
  albumNavigationMap: AlbumNavigationMap = new AlbumNavigationMap();

  deserialize(albumsForArtist: Array<AlbumSummaryWithPlaceToStart>): void {
    this.albumNavigationMap.buildNavigation(albumsForArtist);
  }

}
