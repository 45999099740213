<div class="navbar fixed-bottom bg-body-tertiary" *ngIf="artist && album && getNumberOfAlbums() > 1">
  <div class="container-fluid">
    <ul class="navbar-nav">
      <li class="navbar-item">
        <a *ngIf="getPreviousAlbum()" class="nav-link" 
            routerLink="/artist/{{artist.id}}/album/{{getPreviousAlbum().id}}" 
            title="{{getPreviousAlbum().name}}">Previous Album</a>
      </li>
      <li class="navbar-item ms-auto">
        <a *ngIf="getNextAlbum()" class="nav-link" 
            routerLink="/artist/{{artist.id}}/album/{{getNextAlbum().id}}" 
            title="{{getNextAlbum().name}}">Next Album</a>
      </li>
    </ul>
  </div>
</div>

