import {Component, OnInit} from '@angular/core';
import {MusicCollectionService} from '../../service/MusicCollectionService';
import {MusicCollection} from '../../model/MusicCollection';
import {ArtistSummary} from '../../model/ArtistSummary';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {MusicCollectionSettingsService} from '../../service/MusicCollectionSettingsService';

@Component({
  selector: 'app-music-collection',
  templateUrl: './music-collection.component.html',
  styleUrls: ['./music-collection.component.scss']
})
export class MusicCollectionComponent implements OnInit {

  musicCollection: MusicCollection;
  artistSortView: string = MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL;
  sortedArtists: Array<ArtistSummary>;
  searchText: string;
  searchModelChanged: Subject<string> = new Subject<string>();
  searchTextForFiltering: string;

  constructor(private musicCollectionService: MusicCollectionService,
              private musicCollectionSettingsService: MusicCollectionSettingsService) {
  }

  ngOnInit(): void {
    console.log('in MusicCollectionComponent.ngOnInit()');
    this.initializeArtistSortView();
    this.configureSearchListener();
    this.getMusicCollection();
  }

  getMusicCollection(): void {
    this.musicCollectionService.getMusicCollection().subscribe(result => this.processCollection(result));
  }

  processCollection(artists: Array<ArtistSummary>): void {
    console.log('Processing music collection');
    this.musicCollection = new MusicCollection();
    this.musicCollection.deserializeArtists(artists);
    this.setSortedArtistsBasedOnSortView();
  }

  initializeArtistSortView(): void {
    this.artistSortView = this.musicCollectionSettingsService.getArtistSortViewFromLocalStorage(
      MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL);
  }

  configureSearchListener(): void {
    this.searchModelChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged())
      .subscribe(model => {
        this.searchTextForFiltering = model;
      });
  }

  searchTextChanged(text: string): void {
    this.searchModelChanged.next(text);
  }

  artistSortViewIsAlphabetical(): boolean {
    return this.artistSortView === MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL;
  }

  artistSortViewIsAlbumCount(): boolean {
    return this.artistSortView === MusicCollectionSettingsService.ARTIST_SORT_ALBUM_COUNT;
  }

  sortAlphabetical(): void {
    this.clearSearchText();
    this.artistSortView = MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL;
    this.sortedArtists = this.musicCollection.getArtistsSortedAlphabetically();
    this.musicCollectionSettingsService.storeArtistSortViewInLocalStorage(this.artistSortView);
  }

  sortByMostAlbums(): void {
    this.clearSearchText();
    this.artistSortView = MusicCollectionSettingsService.ARTIST_SORT_ALBUM_COUNT;
    this.sortedArtists = this.musicCollection.getArtistsSortedByMostAlbums();
    this.musicCollectionSettingsService.storeArtistSortViewInLocalStorage(this.artistSortView);
  }

  setSortedArtistsBasedOnSortView(): void {
    switch (this.artistSortView) {
      case MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL:
        this.sortedArtists = this.musicCollection.getArtistsSortedAlphabetically();
        break;
      case MusicCollectionSettingsService.ARTIST_SORT_ALBUM_COUNT:
        this.sortedArtists = this.musicCollection.getArtistsSortedByMostAlbums();
        break;
      default:
        console.log('Unexpected artist sort view of ' + this.artistSortView);
        this.sortedArtists = this.musicCollection.getArtistsSortedAlphabetically();
    }
  }

  clearSearchText(): void {
    this.searchText = '';
    this.searchTextForFiltering = '';
  }



}
