import { Injectable } from '@angular/core';

@Injectable()
export class TimeService {

  convertNumberInMsecsToTimeAsString(timeInMsecs: number): string {
    const seconds = timeInMsecs / 1000;
    return this.formattime(seconds);
  }

  formattime(timeInSeconds: number): string {
    // http://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
    const zero = '0';
    const time = new Date(0, 0, 0, 0, 0, timeInSeconds, 0);
    let hh = '' + time.getHours();
    let mm = '' + time.getMinutes();
    let ss = '' + time.getSeconds();

    // Pad zero values to 00
    hh = (zero + hh).slice(-2);
    mm = (zero + mm).slice(-2);
    ss = (zero + ss).slice(-2);
    if (hh === '00') {
      return mm + ':' + ss;
    } else {
      return hh + ':' + mm + ':' + ss;
    }
  }
}
