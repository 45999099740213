import { Component, Input, OnInit } from '@angular/core';
import { Artist } from 'src/app/model/Artist';
import { ArtistNavigationEntry } from 'src/app/model/ArtistNavigationEntry';
import { ArtistSummary } from 'src/app/model/ArtistSummary';
import { ArtistNavigationService } from 'src/app/service/ArtistNavigationService';
import { MusicCollectionSettingsService } from 'src/app/service/MusicCollectionSettingsService';

@Component({
  selector: 'app-artist-navigation-navbar',
  templateUrl: './artist-navigation-navbar.component.html',
  styleUrls: ['./artist-navigation-navbar.component.scss']
})
export class ArtistNavigationNavbarComponent implements OnInit {

  @Input() artist: Artist;

  artistSortForNavigation: string = MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL;

  constructor(
    private artistNavigationService: ArtistNavigationService,
    private musicCollectionSettingsService: MusicCollectionSettingsService
  ) {
  }  

  ngOnInit(): void {
    console.log('in ArtistNavigationNavbarComponent.ngOnInit()');
    this.initializeArtistSortForNavigation();
  }

  initializeArtistSortForNavigation(): void {
    /*
      if there is an artist sort on the my collection page, use it, otherwise use alphabetical.
     */
    this.artistSortForNavigation = this.musicCollectionSettingsService.getArtistSortViewFromLocalStorage(
      MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL);
  }

  getAlphabeticalArtistNavigationEntry(artistId: string): ArtistNavigationEntry {
    return this.artistNavigationService.getArtistNavigation().artistsAlphabeticalNavigationMap.get(artistId);
  }

  getByAlbumCountArtistNavigationEntry(artistId: string): ArtistNavigationEntry {
    return this.artistNavigationService.getArtistNavigation().artistsByAlbumCountNavigationMap.get(artistId);
  }

  getNumberOfArtists(): number {
    return this.artistNavigationService.getArtistNavigation().artistsByAlbumCountNavigationMap.size();
  }

  getPreviousArtist(): ArtistSummary {
    let previousArtist: ArtistSummary = null;
    switch (this.artistSortForNavigation) {
      case MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL:
        previousArtist = this.getAlphabeticalArtistNavigationEntry(this.artist.id).previousArtist;
        break;
      case MusicCollectionSettingsService.ARTIST_SORT_ALBUM_COUNT:
        previousArtist = this.getByAlbumCountArtistNavigationEntry(this.artist.id).previousArtist;
        break;
    }
    return previousArtist;
  }

  getNextArtist(): ArtistSummary {
    let nextArtist: ArtistSummary = null;
    switch (this.artistSortForNavigation) {
      case MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL:
        nextArtist = this.getAlphabeticalArtistNavigationEntry(this.artist.id).nextArtist;
        break;
      case MusicCollectionSettingsService.ARTIST_SORT_ALBUM_COUNT:
        nextArtist = this.getByAlbumCountArtistNavigationEntry(this.artist.id).nextArtist;
        break;
    }
    return nextArtist;
  }
}
