export class ArtistSummary {
  id: string;
  name: string;
  sortPosition: number;
  albumCount: number;

  deserialize(artistSummary: ArtistSummary): void {
    this.id = artistSummary.id;
    this.name = artistSummary.name;
    this.sortPosition = artistSummary.sortPosition;
    this.albumCount = artistSummary.albumCount;
  }

  getLabelForAlbumCount(): string {
    if (this.albumCount === 1) {
      return '1 album';
    } else {
      return this.albumCount + ' albums';
    }
  }
}
