export class AlbumSummary {
  id: string;
  name: string;
  year: number;
  rating: number;

  deserialize(albumSummaryJson: AlbumSummary): void {
    this.id = albumSummaryJson.id;
    this.name = albumSummaryJson.name;
    this.year = albumSummaryJson.year;
    this.rating = albumSummaryJson.rating;
  }
}
