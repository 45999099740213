import {AlbumNavigationEntry} from './AlbumNavigationEntry';
import {AlbumSummaryWithPlaceToStart} from './AlbumSummaryWithPlaceToStart';

export class AlbumNavigationMap {
  albumNavigationEntries: Map<string, AlbumNavigationEntry>;

  constructor() {
    this.albumNavigationEntries = new Map<string, AlbumNavigationEntry>();
  }

  buildNavigation(albumsForArtist: Array<AlbumSummaryWithPlaceToStart>): void {
    this.clear();
    let previousAlbum = null;
    for (let i = 0; i < albumsForArtist.length; i++) {
      const album: AlbumSummaryWithPlaceToStart = albumsForArtist[i];
      const albumNavigationEntry: AlbumNavigationEntry = new AlbumNavigationEntry();
      albumNavigationEntry.previousAlbum = previousAlbum;
      if (i !== albumsForArtist.length) {
        const nextAlbum = albumsForArtist[i + 1];
        albumNavigationEntry.nextAlbum = nextAlbum;
      }
      previousAlbum = album;
      this.albumNavigationEntries.set(album.id, albumNavigationEntry);
    }
  }

  get(albumId: string): AlbumNavigationEntry {
    return this.albumNavigationEntries.get(albumId);
  }

  size(): number {
    return this.albumNavigationEntries.size;
  }

  clear(): void {
    this.albumNavigationEntries.clear();
  }
}
