import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Artist} from '../../model/Artist';
import {ImageService} from '../../service/ImageService';
import {MusicCollectionService} from '../../service/MusicCollectionService';
import {AlbumSummaryWithPlaceToStart} from '../../model/AlbumSummaryWithPlaceToStart';
import {AlbumSummaryWithPlaceToStartCollection} from '../../model/AlbumSummaryWithPlaceToStartCollection';
import {ArtistNavigationService} from '../../service/ArtistNavigationService';
import {MusicCollectionSettingsService} from '../../service/MusicCollectionSettingsService';
import {ParagraphTextRenderingService} from '../../service/ParagraphTextRenderingService';

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.scss']
})
export class ArtistComponent implements OnInit {

  albumView: string = MusicCollectionSettingsService.TILES;
  artistSortForNavigation: string = MusicCollectionSettingsService.ARTIST_SORT_ALPHABETICAL;
  artist: Artist;
  albums: AlbumSummaryWithPlaceToStartCollection;
  averageStarRating: string;
  placeToStart: AlbumSummaryWithPlaceToStart[];

  constructor(
    private route: ActivatedRoute,
    private musicCollectionService: MusicCollectionService,
    private imageService: ImageService,
    private artistNavigationService: ArtistNavigationService,
    private musicCollectionSettingsService: MusicCollectionSettingsService,
    private paragraphTextRenderingService: ParagraphTextRenderingService
  ) {
    this.placeToStart = new Array<AlbumSummaryWithPlaceToStart>();
  }

  ngOnInit(): void {
    console.log('in ArtistComponent.ngOnInit()');
    // this component is reused when we navigate between artists,
    // so we register to be notified anytime params change.
    this.route.paramMap.subscribe(params => this.handleParamsChange(params));
    this.initializeAlbumView();
  }

  handleParamsChange(params: ParamMap): void {
    this.artist = null;
    this.albums = null;
    this.getArtist();
    this.getAlbums();
  }

  initializeAlbumView(): void {
    this.albumView = this.musicCollectionSettingsService.getAlbumViewFromLocalStorage(MusicCollectionSettingsService.TILES);
  }

  getArtist(): void {
    const id = this.route.snapshot.paramMap.get('artistId');
    this.musicCollectionService.getArtist(id)
      .subscribe(artist => this.artist = artist);
  }

  getAlbums(): void {
    const id = this.route.snapshot.paramMap.get('artistId');
    this.musicCollectionService.getAlbumsForArtist(id)
      .subscribe(albums => this.processAlbums(albums));
  }

  processAlbums(albums: Array<AlbumSummaryWithPlaceToStart>): void {
    this.albums = new AlbumSummaryWithPlaceToStartCollection();
    this.albums.deserializeAlbums(albums);
    this.calculateAverageStarRating(this.albums);
    this.calculatePlaceToStart(this.albums);
  }

  calculateAverageStarRating(albums: AlbumSummaryWithPlaceToStartCollection): void {
    let totalRating = 0.0;
    for (const album of albums.albumSummaries) {
      totalRating += album.rating;
    }
    this.averageStarRating = (totalRating / albums.albumSummaries.length).toFixed(2);
  }

  calculatePlaceToStart(albums: AlbumSummaryWithPlaceToStartCollection): void {
    // first clear array on route change
    this.placeToStart.length = 0;
    for (const album of albums.albumSummaries) {
      if (album.isPlaceToStart()) {
        this.placeToStart.push(album);
      }
    }
  }

  getArtistImg(artistId: string): string {
    return this.imageService.getArtistImg(artistId);
  }

  albumViewIsTiles(): boolean {
    return this.albumView === MusicCollectionSettingsService.TILES;
  }

  albumViewIsTable(): boolean {
    return this.albumView === MusicCollectionSettingsService.TABLE;
  }

  viewTiles(): void {
    console.log('Switching to tile view');
    this.albumView = MusicCollectionSettingsService.TILES;
    this.musicCollectionSettingsService.storeAlbumViewInLocalStorage(this.albumView);
  }

  viewTable(): void {
    console.log('Switching to table view');
    this.albumView = MusicCollectionSettingsService.TABLE;
    this.musicCollectionSettingsService.storeAlbumViewInLocalStorage(this.albumView);
  }

  getArtistDescription(rawDescription: string): string {
    if (!rawDescription) {
      return rawDescription;
    }
    return this.paragraphTextRenderingService.getRenderedText(rawDescription, this.artist.id);
  }

}
