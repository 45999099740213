import {Component, Input} from '@angular/core';
import {ImageService} from '../../service/ImageService';
import {Artist} from '../../model/Artist';
import { AlbumSummary } from 'src/app/model/AlbumSummary';

@Component({
  selector: 'app-album-tile',
  templateUrl: './album-tile.component.html',
  styleUrls: ['./album-tile.component.scss']
})
export class AlbumTileComponent {

  @Input() album: AlbumSummary;
  @Input() artist: Artist;

  constructor(
    private imageService: ImageService
  ) {
  }

  getAlbumCoverImg(artistId: string, albumId: string): string {
    return this.imageService.getSmallAlbumCoverImg(artistId, albumId);
  }

}
