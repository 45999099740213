import { Injectable } from '@angular/core';

@Injectable()
export class StarService {

  getStarLabel(rating: number): string {
    if (rating === 1) {
      return '1 star';
    } else {
      return rating + ' stars';
    }
  };

  getStarClass(position: number, rating: number): string {
    if (rating - position >= 0) {
      return 'star full';
    }
    if (rating - position === -0.5) {
      return 'star half';
    }
    return 'star empty';
  };

}
