import {Component, OnInit} from '@angular/core';
import {ImageService} from '../../service/ImageService';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {MusicCollectionService} from '../../service/MusicCollectionService';
import {Album} from '../../model/Album';
import {Artist} from '../../model/Artist';
import {LogService} from '../../service/LogService';
import {MusicBrainzService} from '../../service/MusicBrainzService';
import {AlbumTrack} from '../../model/AlbumTrack';
import {AlbumDisc} from '../../model/AlbumDisc';
import {TimeService} from '../../service/TimeService';
import {AlbumNavigationService} from '../../service/AlbumNavigationService';
import {ArtistService} from '../../service/ArtistService';
import {tap} from 'rxjs/operators';
import {ParagraphTextRenderingService} from '../..//service/ParagraphTextRenderingService';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {

  album: Album;
  albumDiscs: AlbumDisc[];

  constructor(
    private route: ActivatedRoute,
    private musicCollectionService: MusicCollectionService,
    private musicBrainzService: MusicBrainzService,
    private imageService: ImageService,
    private logService: LogService,
    private timeService: TimeService,
    private artistService: ArtistService,
    private paragraphTextRenderingService: ParagraphTextRenderingService
  ) {
  }

  ngOnInit(): void {
    console.log('in AlbumComponent.ngOnInit()');
    // this component is reused when we navigate between albums of the same artist,
    // so we register to be notified anytime params change.
    this.route.paramMap.subscribe(params => this.handleParamsChange(params));
  }

  handleParamsChange(params: ParamMap): void {
    this.album = null;
    this.albumDiscs = [];
    this.loadAlbum();
  }

  loadAlbum(): void {
    const artistId = this.route.parent.snapshot.paramMap.get('artistId');
    const albumId = this.route.snapshot.paramMap.get('albumId');
    this.musicCollectionService.getAlbum(artistId, albumId)
      .subscribe(album => this.processAlbum(album));
  }

  processAlbum(album: Album): void {
    this.album = new Album();
    this.album.deserialize(album);
    this.loadTracks();
  }

  isSingleDiscAlbum(): boolean {
    return this.albumDiscs.length === 1;
  }

  loadTracks(): void {
    if (this.album.musicBrainzId) {
      this.getTracks(this.album.musicBrainzId, 1);
    }
  }

  getTracks(musicBrainzId: string, tryNumber: number): void {
    this.musicBrainzService.getTracksForAlbum(musicBrainzId)
      .pipe(
        tap(
          next => {},
          error => this.handleMusicBrainzError(musicBrainzId, tryNumber)
        )
      )
      .subscribe(musicBrainzResult => this.processMusicBrainzResult(musicBrainzResult));
  }

  handleMusicBrainzError(musicBrainzId: string, tryNumber: number): void {
    console.log('Failed to contact MusicBrainz on try ' + tryNumber);
    if (tryNumber > 5) {
      this.logService.log('Unable to contact MusicBrainz after 5 tries, aborting.');
      return;
    } else {
      const delay = tryNumber * 1000;
      console.log('Sleeping ' + delay + ' msecs, then will try again');
      setTimeout(() => {
        this.getTracks(musicBrainzId, tryNumber + 1);
      }, delay);
    }
  }

  processMusicBrainzResult(musicBrainzResult: any): void {
    if (!musicBrainzResult || !musicBrainzResult.media || !musicBrainzResult.media.length) {
      return;
    }
    const albumDiscs = new Array<AlbumDisc>();
    for (let i = 0; i < musicBrainzResult.media.length; i++) {
      const disc = musicBrainzResult.media[i];
      const tracks = disc.tracks;
      const title = disc.title;
      let discTitle = 'Disc ' + (i + 1);
      if (disc.title) {
        discTitle += ': ' + disc.title;
      }
      const discTracks = new Array<AlbumTrack>();
      for (const track of tracks) {
        let length = 0;
        if (track.length) {
          length = track.length;
        } else if (track.recording && track.recording.length) {
          // songs on DVDs have time in track.recording.length
          length = track.recording.length;
        }
        const albumTrack = new AlbumTrack();
        albumTrack.number = track.number;
        albumTrack.title = track.title;
        albumTrack.length = this.timeService.convertNumberInMsecsToTimeAsString(length);
        discTracks.push(albumTrack);
      }
      const albumDisc = new AlbumDisc();
      albumDisc.number = i + 1;
      albumDisc.title = discTitle;
      albumDisc.tracks = discTracks;
      albumDiscs.push(albumDisc);
    }
    this.albumDiscs = albumDiscs;
  }

  getAlbumCoverImg(artistId: string, albumId: string): string {
    return this.imageService.getLargeAlbumCoverImg(artistId, albumId);
  }

  getArtist(): Artist {
    return this.artistService.artist;
  }

  getAlbumDescription(rawDescription: string, artistId: string): string {
    if (!rawDescription) {
      return rawDescription;
    }
    return this.paragraphTextRenderingService.getRenderedText(rawDescription, artistId);
  }

}
