import { Injectable } from '@angular/core';
import { ArtistLinkService } from './ArtistLinkService';
import { AlbumLinkService } from './AlbumLinkService';
import { YouTubeLinkService } from './YouTubeLinkService';

@Injectable()
export class ParagraphTextRenderingService {

  constructor(
    private artistLinkService: ArtistLinkService,
    private albumLinkService: AlbumLinkService,
    private youTubeLinkService: YouTubeLinkService) { }

  getRenderedText(text: string, artistId: string): string {
    if (!text) {
      return text;
    }
    let updatedText = this.artistLinkService.updateTextWithArtistLinks(text);
    updatedText = this.albumLinkService.updateTextWithAlbumLinks(updatedText, artistId);
    updatedText = this.youTubeLinkService.updateTextWithYouTubeLinks(updatedText);
    return updatedText;
  }

}
