import {ArtistSummary} from './ArtistSummary';

export class ArtistNavigationEntry {
  previousArtist: ArtistSummary;
  nextArtist: ArtistSummary;

  constructor() {
    this.previousArtist = null;
    this.nextArtist = null;
  }
}
