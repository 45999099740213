import {Component, OnInit} from '@angular/core';
import {ArtistNavigationService} from '../../service/ArtistNavigationService';

@Component({
  selector: 'app-all-artists',
  templateUrl: './all-artists.component.html'
})
export class AllArtistsComponent implements OnInit {

  constructor(private artistNavigationService: ArtistNavigationService) {
  }

  ngOnInit(): void {
    console.log('in AllArtistsComponent.ngOnInit()');
    this.artistNavigationService.loadArtistNavigationCollection();
  }
}
