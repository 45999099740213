import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MusicCollectionComponent} from './component/music-collection/music-collection.component';
import {ArtistComponent} from './component/artist/artist.component';
import {AlbumComponent} from './component/album/album.component';
import {AlbumsForArtistComponent} from './component/albums-for-artist/albums-for-artist.component';
import {AllArtistsComponent} from './component/all-artists/all-artists.component';

const routes: Routes = [
  { path: '', redirectTo: '/collection', pathMatch: 'full' },
  { path: 'collection', component: MusicCollectionComponent },
  { path: 'artist', component: AllArtistsComponent,
    children: [
      {path: '', redirectTo: '/collection', pathMatch: 'full'},
      {path: ':artistId', component: ArtistComponent }
    ]},
  { path: 'artist/:artistId', component: ArtistComponent },
  { path: 'artist/:artistId/album', component: AlbumsForArtistComponent,
    children: [
      {path: '', redirectTo: '/collection', pathMatch: 'full'},
      {path: ':albumId', component: AlbumComponent }
    ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
