import {AlbumSummary} from './AlbumSummary';

export class AlbumNavigationEntry {
  previousAlbum: AlbumSummary;
  nextAlbum: AlbumSummary;

  constructor() {
    this.previousAlbum = null;
    this.nextAlbum = null;
  }
}
