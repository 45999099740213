import { Injectable } from '@angular/core';
import {MusicCollectionService} from './MusicCollectionService';
import {ArtistSummary} from '../model/ArtistSummary';
import {ArtistNavigation} from '../model/ArtistNavigation';

@Injectable()
export class ArtistNavigationService {
  artistNavigation: ArtistNavigation = new ArtistNavigation();

  constructor(
    private musicCollectionService: MusicCollectionService) { }

  loadArtistNavigationCollection(): void {
    this.musicCollectionService.getAllArtists().subscribe(result => this.processArtists(result));
  }

  processArtists(artists: Array<ArtistSummary>): void {
    this.artistNavigation.deserialize(artists);
  }

  getArtistNavigation(): ArtistNavigation {
    return this.artistNavigation;
  }
}
