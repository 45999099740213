import { Injectable } from '@angular/core';
import {MusicCollectionService} from './MusicCollectionService';
import {AlbumSummaryWithPlaceToStart} from '../model/AlbumSummaryWithPlaceToStart';
import {AlbumNavigation} from '../model/AlbumNavigation';

@Injectable()
export class AlbumNavigationService {

  albumNavigation: AlbumNavigation = new AlbumNavigation();

  constructor(
    private musicCollectionService: MusicCollectionService) { }

  loadAlbumNavigationCollection(artistId: string): void {
    this.musicCollectionService.getAlbumsForArtist(artistId).subscribe(result => this.processAlbumsForArtist(result));
  }

  processAlbumsForArtist(albumsForArtist: Array<AlbumSummaryWithPlaceToStart>): void {
    this.albumNavigation.deserialize(albumsForArtist);
  }

  getAlbumNavigation(): AlbumNavigation {
    return this.albumNavigation;
  }
}
