import {ArtistSummary} from './ArtistSummary';

export class MusicCollection {
  artists: ArtistSummary[];
  artistsSortedByMostAlbums: ArtistSummary[];

  constructor() {
    this.artists = new Array<ArtistSummary>();
  }

  deserializeArtists(artistsJson: ArtistSummary[]): void {
    for (const artistJson of artistsJson) {
      const artistSummary = new ArtistSummary();
      artistSummary.deserialize(artistJson);
      this.artists.push(artistSummary);
    }
    this.buildArtistsSortedByMostAlbums();
  }

  buildArtistsSortedByMostAlbums(): void {
    const artistsSortedByMostAlbums: Array<ArtistSummary> = this.artists.slice(0);
    artistsSortedByMostAlbums.sort(
      (leftSide: ArtistSummary,
       rightSide: ArtistSummary) => {
        if (leftSide.albumCount > rightSide.albumCount) {
          return -1;
        } else if (leftSide.albumCount < rightSide.albumCount) {
          return 1;
        } else {
          return leftSide.sortPosition - rightSide.sortPosition;
        }
      });
    this.artistsSortedByMostAlbums = artistsSortedByMostAlbums;
  }

  getArtistsSortedAlphabetically(): Array<ArtistSummary> {
    return this.artists;
  }

  getArtistsSortedByMostAlbums(): Array<ArtistSummary> {
    return this.artistsSortedByMostAlbums;
  }

}
