import {Component, Input} from '@angular/core';
import {Artist} from '../../model/Artist';
import {AlbumSummary} from '../../model/AlbumSummary';

@Component({
  selector: 'app-albums-tiles',
  templateUrl: './albums-tiles.component.html',
  styleUrls: ['./albums-tiles.component.scss']
})
export class AlbumsTilesComponent {

  @Input()
  artist: Artist;

  @Input()
  albums: AlbumSummary[];

}
