import {Component, Input} from '@angular/core';
import {AlbumDisc} from '../../model/AlbumDisc';

@Component({
  selector: 'app-disc',
  templateUrl: './disc.component.html',
  styleUrls: ['./disc.component.scss']
})
export class DiscComponent {

  @Input() disc: AlbumDisc;

  @Input() singleDiscAlbum: boolean;
}
