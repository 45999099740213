import { Injectable } from '@angular/core';

@Injectable()
export class AlbumLinkService {

  private static readonly ALBUM_REGEX:RegExp = /<album id=\"(\w+?)\">(.+?)<\/album>/g;
  private static readonly ALBUM_WITH_ARTIST_ID_SECOND_REGEX:RegExp = /<album id=\"(\w+?)\" artistId=\"(\w+?)\">(.+?)<\/album>/g;
  private static readonly ALBUM_WITH_ARTIST_ID_FIRST_REGEX:RegExp = /<album artistId=\"(\w+?)\" id=\"(\w+?)\">(.+?)<\/album>/g;

  updateTextWithAlbumLinks(text: string, artistId: string): string {
    if (text === null) {
      return text;
    }
    /*
     * i'd prefer to use routerLink here, but the returned code won't get post-processed
     * by Angular, so i need to go straight to the href it would create had routerLink been used.
     */
    let albumLinkClass = "class=\"albumLinkInParagraph\"";
    let updatedText = text.replaceAll(AlbumLinkService.ALBUM_WITH_ARTIST_ID_FIRST_REGEX, "<a href=\"/artist/$1/album/$2\" " + albumLinkClass + ">$3</a>");
    updatedText = updatedText.replaceAll(AlbumLinkService.ALBUM_WITH_ARTIST_ID_SECOND_REGEX, "<a href=\"/artist/$2/album/$1\" " + albumLinkClass + ">$3</a>");
    updatedText = updatedText.replaceAll(AlbumLinkService.ALBUM_REGEX, "<a href=\"/artist/" + artistId + "/album/$1\" " + albumLinkClass + ">$2</a>");
    return updatedText;
  };

}
