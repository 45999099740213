import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import {Album} from '../model/Album';
import {LogService} from './LogService';
import {Artist} from '../model/Artist';
import {AlbumSummaryWithPlaceToStart} from '../model/AlbumSummaryWithPlaceToStart';
import {ArtistSummary} from '../model/ArtistSummary';
import {shareReplay} from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class MusicCollectionService {

  private static readonly URL_BASE = 'assets/json';
  private readonly musicCollectionCachedObservable: Observable<Array<ArtistSummary>>;

  constructor(
    private http: HttpClient,
    private logService: LogService) {
    console.log('in MusicCollectionService constructor');
    /* the largest non-image data we get is the full collection.json, so we use caching
       to only get it once, even if we navigate away from the main page to child pages.
       otherwise there is a noticeable loading indicator every time we go to the main page.
       although we set this here, the actual API call doesn't happen until the component
       that calls getMusicCollection() subscribes to it. could have cached it multiple ways
       but wanted to try out shareReplay().
     */
    this.musicCollectionCachedObservable = this.http.get<Array<ArtistSummary>>(
      MusicCollectionService.URL_BASE + '/collection.json', httpOptions).pipe(shareReplay(1));
  }

  getMusicCollection(): Observable<Array<ArtistSummary>> {
    return this.musicCollectionCachedObservable;
  }

  getArtist(artistId: string): Observable<Artist> {
    const url = MusicCollectionService.URL_BASE + `/artists/${artistId}/artist.json`;
    return this.http.get<Artist>(url, httpOptions);
  }

  getAlbum(artistId: string, albumId: string): Observable<Album> {
    const url = MusicCollectionService.URL_BASE + `/artists/${artistId}/albums/${albumId}.json`;
    return this.http.get<Album>(url, httpOptions);
  }

  getAlbumsForArtist(artistId: string): Observable<Array<AlbumSummaryWithPlaceToStart>> {
    const url = MusicCollectionService.URL_BASE + `/artists/${artistId}/albums.json`;
    return this.http.get<Array<AlbumSummaryWithPlaceToStart>>(url, httpOptions);
  }

  getAllArtists(): Observable<Array<ArtistSummary>> {
    const url = MusicCollectionService.URL_BASE + '/artists/artists.json';
    return this.http.get<Array<ArtistSummary>>(url, httpOptions);
  }

}
