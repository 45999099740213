import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import {LogService} from './LogService';

/**
 * text/plain gets past CORS issue
 * https://stackoverflow.com/questions/36353532/angular2-options-method-sent-when-asking-for-http-get
 */
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
};

@Injectable()
export class MusicBrainzService {

  constructor(
    private http: HttpClient,
    private logService: LogService) { }

  getTracksForAlbum(musicBrainzId: string): Observable<Response> {
    const url = `https://musicbrainz.org/ws/2/release/${musicBrainzId}?inc=recordings&fmt=json`;
    return this.http.get<Response>(url, httpOptions);
  }

}
