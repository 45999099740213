import { Pipe, PipeTransform } from '@angular/core';
import {ArtistSummary} from '../model/ArtistSummary';
@Pipe({
  name: 'filterByName'
})
export class FilterByNamePipe implements PipeTransform {

  transform(items: ArtistSummary[], searchText: string): ArtistSummary[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();

    return items.filter( artist => {
      return artist.name.toLowerCase().includes(searchText);
    });
  }
}
