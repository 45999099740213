<div class="albumImageContainer">
  <a routerLink="/artist/{{artist.id}}/album/{{album.id}}">
    <span class="verticalAlignMiddleHelper"></span>
    <img [src]="getAlbumCoverImg(artist.id, album.id)"
         class="albumTileImage"
         onerror="this.src='https://via.placeholder.com/150x150&text=Not Available'"/>
  </a>
</div>
<br>
<a routerLink="/artist/{{artist.id}}/album/{{album.id}}">{{album.name}}</a>
<br>
<span>{{album.year}}</span>
<br>
<app-rating [rating]="album.rating"></app-rating>
