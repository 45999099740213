<div class="navbar fixed-bottom bg-body-tertiary" *ngIf="artist && getNumberOfArtists() > 1">
  <div class="container-fluid">
    <ul class="navbar-nav">
      <li class="navbar-item">
        <a *ngIf="getPreviousArtist()" class="nav-link" routerLink="/artist/{{getPreviousArtist().id}}" 
            title="{{getPreviousArtist().name}}">Previous Artist</a>
      </li>
      <li class="navbar-item ms-auto">
        <a *ngIf="getNextArtist()" class="nav-link" routerLink="/artist/{{getNextArtist().id}}" 
            title="{{getNextArtist().name}}">Next Artist</a>
      </li>
    </ul>
  </div>
</div>