import {ArtistNavigationEntry} from './ArtistNavigationEntry';
import {ArtistSummary} from './ArtistSummary';

export class ArtistNavigationMap {
  artistNavigationEntries: Map<string, ArtistNavigationEntry>;

  constructor() {
    this.artistNavigationEntries = new Map<string, ArtistNavigationEntry>();
  }

  buildNavigation(artists: Array<ArtistSummary>): void {
    let previousArtist = null;
    for (let i = 0; i < artists.length; i++) {
      const artist: ArtistSummary = artists[i];
      const artistNavigationEntry: ArtistNavigationEntry = new ArtistNavigationEntry();
      artistNavigationEntry.previousArtist = previousArtist;
      if (i !== artists.length) {
        const nextArtist = artists[i + 1];
        artistNavigationEntry.nextArtist = nextArtist;
      }
      previousArtist = artist;
      this.artistNavigationEntries.set(artist.id, artistNavigationEntry);
    }
  }

  get(artistId: string): ArtistNavigationEntry {
    return this.artistNavigationEntries.get(artistId);
  }

  size(): number {
    return this.artistNavigationEntries.size;
  }
}
