import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {Router, Scroll} from '@angular/router';
import {ViewportScroller} from '@angular/common';
import {filter} from 'rxjs/operators';
import {AppComponent} from './app.component';
import {MusicCollectionService} from './service/MusicCollectionService';
import {MusicCollectionComponent} from './component/music-collection/music-collection.component';
import {AppRoutingModule} from './app-routing.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ImageService} from './service/ImageService';
import {StarService} from './service/StarService';
import {LogService} from './service/LogService';
import {ArtistComponent} from './component/artist/artist.component';
import {AlbumComponent} from './component/album/album.component';
import {FilterByNamePipe} from './pipe/FilterByNamePipe';
import {FormsModule} from '@angular/forms';
import {MusicBrainzService} from './service/MusicBrainzService';
import {TimeService} from './service/TimeService';
import {AlbumTileComponent} from './component/album-tile/album-tile.component';
import {DiscComponent} from './component/disc/disc.component';
import {RatingComponent} from './component/rating/rating.component';
import {AlbumsForArtistComponent} from './component/albums-for-artist/albums-for-artist.component';
import {AlbumNavigationService} from './service/AlbumNavigationService';
import {ArtistService} from './service/ArtistService';
import {ArtistNavigationService} from './service/ArtistNavigationService';
import {AllArtistsComponent} from './component/all-artists/all-artists.component';
import {AlbumsTableComponent} from './component/albums-table/albums-table.component';
import {AlbumsTilesComponent} from './component/albums-tiles/albums-tiles.component';
import {LocalStorageService} from './service/LocalStorageService';
import {MusicCollectionSettingsService} from './service/MusicCollectionSettingsService';
import {ArtistCardComponent} from './component/artist-card/artist-card.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MusicCollectionNavbarComponent } from './component/music-collection-navbar/music-collection-navbar.component';
import { ArtistNavigationNavbarComponent } from './component/artist-navigation-navbar/artist-navigation-navbar.component';
import { AlbumNavigationNavbarComponent } from './component/album-navigation-navbar/album-navigation-navbar.component';
import { ArtistLinkService } from './service/ArtistLinkService';
import { AlbumLinkService } from './service/AlbumLinkService';
import { ParagraphTextRenderingService } from './service/ParagraphTextRenderingService';
import { YouTubeLinkService } from './service/YouTubeLinkService';

@NgModule({ declarations: [
        AppComponent,
        MusicCollectionComponent,
        ArtistCardComponent,
        AllArtistsComponent,
        ArtistComponent,
        AlbumsForArtistComponent,
        AlbumComponent,
        AlbumTileComponent,
        AlbumsTableComponent,
        AlbumsTilesComponent,
        DiscComponent,
        RatingComponent,
        FilterByNamePipe,
        MusicCollectionNavbarComponent,
        ArtistNavigationNavbarComponent,
        AlbumNavigationNavbarComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FontAwesomeModule,
        FormsModule,
        BsDropdownModule.forRoot(),
        BrowserAnimationsModule], providers: [MusicCollectionService, MusicBrainzService, ImageService, StarService, LogService, TimeService,
        ArtistService, AlbumNavigationService, ArtistNavigationService, LocalStorageService, MusicCollectionSettingsService,
        ArtistLinkService, AlbumLinkService, YouTubeLinkService, ParagraphTextRenderingService, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    // hack from https://github.com/angular/angular/issues/24547
    router.events.pipe(
      filter((e): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        setTimeout(() => {viewportScroller.scrollToPosition(e.position); }, 250);
      } else if (e.anchor) {
        // anchor navigation
        setTimeout(() => {viewportScroller.scrollToAnchor(e.anchor); }, 0);
      } else {
        // forward navigation
        setTimeout(() => {viewportScroller.scrollToPosition([0, 0]); }, 0);
      }
    });
  }
}
