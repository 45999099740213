import {Component, OnInit} from '@angular/core';
import {AlbumNavigationService} from '../../service/AlbumNavigationService';
import {ActivatedRoute} from '@angular/router';
import {ArtistService} from '../../service/ArtistService';

@Component({
  selector: 'app-albums-for-artist',
  templateUrl: './albums-for-artist.component.html'
})
export class AlbumsForArtistComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private artistService: ArtistService,
              private albumNavigationService: AlbumNavigationService) {
  }

  ngOnInit(): void {
    console.log('in AlbumsForArtistComponent.ngOnInit()');
    const artistId = this.route.snapshot.paramMap.get('artistId');
    this.artistService.loadArtist(artistId);
    this.albumNavigationService.loadAlbumNavigationCollection(artistId);
  }
}
