import {ArtistSummary} from './ArtistSummary';
import {ArtistNavigationMap} from './ArtistNavigationMap';

export class ArtistNavigation {

  artistsAlphabeticalNavigationMap: ArtistNavigationMap = new ArtistNavigationMap();
  artistsByAlbumCountNavigationMap: ArtistNavigationMap = new ArtistNavigationMap();

  deserialize(artists: Array<ArtistSummary>): void {
    this.artistsAlphabeticalNavigationMap.buildNavigation(artists);
    const artistsSortedByMostAlbums: Array<ArtistSummary> = artists.slice(0);
    artistsSortedByMostAlbums.sort(
      (leftSide: ArtistSummary,
       rightSide: ArtistSummary) => {
        if (leftSide.albumCount > rightSide.albumCount) {
          return -1;
        } else if (leftSide.albumCount < rightSide.albumCount) {
          return 1;
        } else {
          return leftSide.sortPosition - rightSide.sortPosition;
        }
      });
    this.artistsByAlbumCountNavigationMap.buildNavigation(artistsSortedByMostAlbums);
  }

}
