import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

  public hasProperty(property: string): boolean {
    const value: string = localStorage.getItem(property);
    if (value) {
      return true;
    } else {
      return false;
    }
  }

  public getPropertyValue(propertyName: string): string {
    return localStorage.getItem(propertyName);
  }

  public savePropertyValue(propertyName: string, propertyValue: string): void {
    localStorage.setItem(propertyName, propertyValue);
  }

}
