<h4 *ngIf="!singleDiscAlbum" class="discTitle">{{disc.title}}</h4>
<table class="trackTable">
  <tr>
    <th class="trackColumnHeader">Track</th>
    <th class="titleColumnHeader">Title</th>
    <th class="timeColumnHeader">Length</th>
  </tr>
  <tr *ngFor="let track of disc.tracks">
    <td class="trackColumnData" data-ta-name="trackNumber">{{track.number}}</td>
    <td class="titleColumnData" data-ta-name="trackTitle">{{track.title}}</td>
    <td class="timeColumnData" data-ta-name="trackLength">{{track.length}}</td>
  </tr>
</table>
