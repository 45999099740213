import { Injectable } from '@angular/core';

@Injectable()
export class YouTubeLinkService {

  private static readonly YOU_TUBE_REGEX:RegExp = /<youtube url=\"(.*?)\">(.+?)<\/youtube>/g;

  updateTextWithYouTubeLinks(text: string): string {
    if (text === null) {
      return text;
    }
    let youTubeLinkClass = "class=\"youTubeLinkInParagraph\"";
    /*
     * target is not working as i intend.  i'd like each link to youtube to re-use the same (new) tab, but
     * instead we get a new tab every time.  that's better than opening in the current tab, but not what i want.
     * this is probably due to the manner in which i use innerHTML.
     */
    let updatedText = text.replaceAll(YouTubeLinkService.YOU_TUBE_REGEX, "<span " + youTubeLinkClass + "><img src=\"assets/external/yt_icon_rgb.png\"><a href=\"$1\" target=\"MyMusicCollection_YouTube\">$2</a></span>");
    return updatedText;
  };

}
