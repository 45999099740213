import {Component, Input} from '@angular/core';
import {ImageService} from '../../service/ImageService';
import {Artist} from '../../model/Artist';
import {AlbumSummaryWithPlaceToStartCollection} from '../../model/AlbumSummaryWithPlaceToStartCollection';

@Component({
  selector: 'app-albums-table',
  templateUrl: './albums-table.component.html',
  styleUrls: ['./albums-table.component.scss']
})
export class AlbumsTableComponent {

  @Input()
  artist: Artist;

  @Input()
  albums: AlbumSummaryWithPlaceToStartCollection;

  constructor(
    private imageService: ImageService
  ) {
  }

  getAlbumCoverImg(artistId: string, albumId: string): string {
    return this.imageService.getSmallAlbumCoverImg(artistId, albumId);
  }

}
