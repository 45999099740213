import {Component, Input} from '@angular/core';
import {ImageService} from '../../service/ImageService';
import {ArtistSummary} from '../../model/ArtistSummary';

@Component({
  selector: 'app-artist-card',
  templateUrl: './artist-card.component.html',
  styleUrls: ['./artist-card.component.scss']
})
export class ArtistCardComponent {

  @Input() artist: ArtistSummary;

  constructor(
    private imageService: ImageService
  ) {
  }

  getArtistImg(artistId: string): string {
    return this.imageService.getArtistImg(artistId);
  }

}
