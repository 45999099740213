import {Component, Input} from '@angular/core';
import {StarService} from '../../service/StarService';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent {

  @Input() rating: number;

  constructor(
    private starService: StarService
  ) {
  }

  getStarLabel(): string {
    return this.starService.getStarLabel(this.rating);
  }

  getStarClass(position: number): string {
    return this.starService.getStarClass(position, this.rating);
  }

}
