import { Component, Input } from '@angular/core';
import { Album } from 'src/app/model/Album';
import { AlbumNavigationEntry } from 'src/app/model/AlbumNavigationEntry';
import { AlbumSummary } from 'src/app/model/AlbumSummary';
import { Artist } from 'src/app/model/Artist';
import { AlbumNavigationService } from 'src/app/service/AlbumNavigationService';

@Component({
  selector: 'app-album-navigation-navbar',
  templateUrl: './album-navigation-navbar.component.html',
  styleUrls: ['./album-navigation-navbar.component.scss']
})
export class AlbumNavigationNavbarComponent {

  @Input() artist: Artist;

  @Input() album: Album;

  constructor(
    private albumNavigationService: AlbumNavigationService
  ) {
  }  

  getAlbumNavigationEntry(albumId: string): AlbumNavigationEntry {
    return this.albumNavigationService.getAlbumNavigation().albumNavigationMap.get(albumId);
  }

  getNumberOfAlbums(): number {
    return this.albumNavigationService.getAlbumNavigation().albumNavigationMap.size();
  }

  getPreviousAlbum(): AlbumSummary {
    return this.getAlbumNavigationEntry(this.album.id).previousAlbum;
  }

  getNextAlbum(): AlbumSummary {
    return this.getAlbumNavigationEntry(this.album.id).nextAlbum;
  }

}
