<div class="collectionView">
  <div *ngIf="!sortedArtists" class="loadingContainer">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div *ngIf="sortedArtists">
    <div class="artistHeadingAndControlsContainer">
      <h2>Artists <small class="text-muted">({{sortedArtists.length}})</small></h2>
      <div class="artistViewControls">
        <div class="searchControls">
          <div class="input-group">
            <input type="search" class="form-control" placeholder="Search for artist"
                   name="search-term" id="search-term" [(ngModel)]="searchText" (ngModelChange)="searchTextChanged($event)"/>
            <span class="input-group-text">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </div>
        <div class="dropdown" dropdown>
          <button type="button" class="btn btn-light dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" dropdownToggle>
            Sort By
          </button>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="sortAlphabetical()" href="javascript:void(0)">
              <i class="fa fa-fw fa-check" *ngIf="artistSortViewIsAlphabetical()"></i>
              <i class="fa fa-fw" *ngIf="!artistSortViewIsAlphabetical()"></i>
              Artist Name
            </a>
            <a class="dropdown-item" (click)="sortByMostAlbums()" href="javascript:void(0)">
              <i class="fa fa-fw fa-check" *ngIf="artistSortViewIsAlbumCount()"></i>
              <i class="fa fa-fw" *ngIf="!artistSortViewIsAlbumCount()"></i>
              Album Count
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="card-group">
      <div *ngFor="let artist of sortedArtists | filterByName : searchTextForFiltering">
        <app-artist-card [artist]="artist"></app-artist-card>
      </div>
    </div>
  </div>
</div>
