<table class="albumsTable">
  <tr>
    <th class="imageColumnHeader">Image</th>
    <th class="yearColumnHeader">Year</th>
    <th class="titleColumnHeader">Title</th>
    <th class="ratingColumnHeader">Rating</th>
  </tr>
  <tr *ngFor="let album of albums.albumSummaries">
    <td class="imageColumnData">
      <a routerLink="/artist/{{artist.id}}/album/{{album.id}}">
        <span class="verticalAlignMiddleHelper"></span>
        <img [src]="getAlbumCoverImg(artist.id, album.id)"
             class="albumTileImage"
             onerror="this.src='https://via.placeholder.com/150x150&text=Not Available'"/>
      </a>
    </td>
    <td class="yearColumnData">{{album.year}}</td>
    <td class="titleColumnData">
      <a routerLink="/artist/{{artist.id}}/album/{{album.id}}">{{album.name}}</a>
    </td>
    <td class="ratingColumnData">
      <app-rating [rating]="album.rating"></app-rating>
    </td>
  </tr>
</table>
