import {AlbumSummary} from './AlbumSummary';

export class Album extends AlbumSummary {
  description: string;
  musicBrainzId: string;
  allMusicReviewUrl: string;
  amazonMusicUrl: string;

  deserialize(albumJson: Album): void {
    super.deserialize(albumJson);
    this.description = albumJson.description;
    this.musicBrainzId = albumJson.musicBrainzId;
    this.allMusicReviewUrl = albumJson.allMusicReviewUrl;
    this.amazonMusicUrl = albumJson.amazonMusicUrl;
  }

  public hasExternalLinks(): boolean {
    return this.hasAllMusicReviewUrl() || this.hasAmazonMusicUrl();
  }
  
  public hasAllMusicReviewUrl(): boolean {
    return !this.isEmpty(this.allMusicReviewUrl);
  }

  public hasAmazonMusicUrl(): boolean {
    return !this.isEmpty(this.amazonMusicUrl);
  }

  private isEmpty(str: string) {
    if (str === undefined) {
      return true;
    }
    if (str === null) {
      return true;
    }
    if (str.trim().length === 0) {
      return true;
    }
    return false;
  }
}
