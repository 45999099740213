<div *ngIf="artist" class="artistView">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/collection" data-ta-name="artistsLink">Artists</a></li>
      <li class="breadcrumb-item active" aria-current="page" data-ta-name="artistNameInBreadcrumb">{{artist.name}}</li>
    </ol>
  </nav>
  <h1 class="artistName" data-ta-name="artistName">{{artist.name}}</h1>
  <div class="artistImageAndSummaryContainer">
    <img [src]="getArtistImg(artist.id)"
         class="artistImage"
         onerror="this.src='https://via.placeholder.com/400x250&text=Not Available'">
    <div class="artistSummaryContainer">
      <h5>Artist Notes</h5>
      <p [innerHTML]="getArtistDescription(artist.description)" data-ta-name="artistNotes"></p>
      <div *ngIf="albums">
        <div *ngIf="placeToStart">
          <h5 *ngIf="placeToStart.length !== 0">Place to Start</h5>
          <div *ngFor="let album of placeToStart">
            <a routerLink="/artist/{{artist.id}}/album/{{album.id}}" data-ta-name="placeToStartLink">{{album.name}}</a>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="albums">
    <div class="albumsHeadingAndControlsContainer">
      <h2>Albums <small class="text-muted">({{albums.albumSummaries.length}})</small></h2>
      <div class="albumViewControls">
        <span *ngIf="albumViewIsTiles()">Tile View</span>
        <a *ngIf="!albumViewIsTiles()" (click)="viewTiles()" href="javascript:void(0)" data-ta-name="tileViewLink">Tile View</a>
        |
        <span *ngIf="albumViewIsTable()">Table View</span>
        <a *ngIf="!albumViewIsTable()" (click)="viewTable()" href="javascript:void(0)" data-ta-name="tableViewLink">Table View</a>
      </div>
    </div>
    <div *ngIf="albumViewIsTiles()">
      <app-albums-tiles [albums]="albums.albumSummaries" [artist]="artist"></app-albums-tiles>
    </div>
    <div *ngIf="albumViewIsTable()">
      <app-albums-table [albums]="albums" [artist]="artist"></app-albums-table>
    </div>
  </div>
</div>
<app-artist-navigation-navbar [artist]="artist"></app-artist-navigation-navbar>
