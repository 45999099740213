<div *ngIf="album && getArtist()" class="albumView">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/collection" data-ta-name="artistsLink">Artists</a></li>
      <li class="breadcrumb-item"><a routerLink="/artist/{{getArtist().id}}" data-ta-name="artistNameInBreadcrumb">{{getArtist().name}}</a></li>
      <li class="breadcrumb-item active" aria-current="page" data-ta-name="albumNameInBreadcrumb">{{album.name}}</li>
    </ol>
  </nav>  
  <h1 class="albumName" data-ta-name="albumName">{{album.name}}</h1>
  <div class="albumImageAndSummaryContainer">
    <img [src]="getAlbumCoverImg(getArtist().id, album.id)"
         class="albumImage"
         onerror="this.src='https://via.placeholder.com/300x300&text=Not Available'">
    <div class="albumSummaryContainer">
      <h5>Album Notes</h5>
      <p [innerHTML]="getAlbumDescription(album.description, getArtist().id)" data-ta-name="albumNotes"></p>
      <h5>Release Year</h5>
      <p data-ta-name="albumYear">{{album.year}}</p>
      <h5>Star Rating</h5>
      <app-rating [rating]="album.rating"></app-rating>
      <div *ngIf="album.hasExternalLinks()" class="externalLinks">
        <h5>External Links</h5>
        <span *ngIf="album.hasAllMusicReviewUrl()" class="allMusic">
          <img src="assets/external/AllMusic_Logo.svg" alt="AllMusic"><a href="{{album.allMusicReviewUrl}}" target="MyMusicCollection_AllMusic">AllMusic Review</a>
        </span>
        <span *ngIf="album.hasAmazonMusicUrl()" class="amazonMusic">
          <img src="assets/external/amazon.png" alt="Amazon Music"><a href="{{album.amazonMusicUrl}}" target="MyMusicCollection_AmazonMusic">Listen at Amazon Music</a>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="albumDiscs && albumDiscs.length > 0">
    <div class="tracksHeadingContainer">
      <h2 class="tracksTitle">Tracks</h2>
    </div>
    <div *ngFor="let disc of albumDiscs" class="discContainer">
      <app-disc [disc]="disc" [singleDiscAlbum]="isSingleDiscAlbum()"></app-disc>
    </div>
  </div>
</div>
<app-album-navigation-navbar [artist]="getArtist()" [album]="album"></app-album-navigation-navbar>
