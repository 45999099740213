import { Injectable } from '@angular/core';

@Injectable()
export class ArtistLinkService {

  private static readonly ARTIST_REGEX:RegExp = /<artist id=\"(\w+?)\">(.+?)<\/artist>/g;

  updateTextWithArtistLinks(text: string): string {
    if (text === null) {
      return text;
    }
    /*
     * i'd prefer to use routerLink here, but the returned code won't get post-processed
     * by Angular, so i need to go straight to the href it would create had routerLink been used.
     */
    return text.replaceAll(ArtistLinkService.ARTIST_REGEX, "<a href=\"/artist/$1\">$2</a>");
  };

}
