import {AlbumSummary} from './AlbumSummary';

export class AlbumSummaryWithPlaceToStart extends AlbumSummary {
  placeToStart: boolean;

  deserialize(albumJson: AlbumSummaryWithPlaceToStart): void {
    super.deserialize(albumJson);
    this.placeToStart = albumJson.placeToStart;
  }

  isPlaceToStart(): boolean {
    return this.placeToStart === true;
  }
}
